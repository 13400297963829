import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { createZustandSelectors } from '~/lib/create-zustand-selectors'

export const searchCategories = ['users', 'accounts', 'events'] as const

type SearchCategory = (typeof searchCategories)[number]

interface CommandKState {
	searchTerms: string
	setSearchTerms: (searchTerms: string) => void

	searchCategory: SearchCategory
	setSearchCategory: (searchCategory: SearchCategory) => void
}

const useCommandKStoreBase = create<CommandKState>()(
	devtools(
		(set) => ({
			searchTerms: '',
			setSearchTerms: (searchTerms) => set({ searchTerms }),

			searchCategory: 'events',
			setSearchCategory: (searchCategory) => set({ searchCategory }),
		}),
		{ name: 'command-k-storage' },
	),
)

export const useCommandKStore = createZustandSelectors(useCommandKStoreBase)
