import { TriangleAlert } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import type { PropsWithChildren } from 'react'
import { AppNavbar } from '~/components/app-navbar'
import { PageLoader } from '~/components/page-loader'
import { HeaderIcon } from '~/components/ui/header-icon'
import { AuthDialog } from '~/domains/auth/components/auth-dialog'

export const ScopesLayout = ({ children }: PropsWithChildren) => {
	const router = useRouter()
	const { t } = useTranslation()

	if (!router.isReady) return <PageLoader />

	return (
		<>
			<AppNavbar />

			<div
				key={router.route}
				data-testid='layout-content'
				className='mt-[60px] overflow-y-auto print:mt-6'
			>
				{router.query.scopes ? (
					children
				) : (
					<div className='flex h-[400px] flex-col items-center justify-center gap-4'>
						<HeaderIcon>
							<TriangleAlert size={26} />
						</HeaderIcon>

						{t('errors:noScopesInUrl')}
					</div>
				)}
			</div>

			<AuthDialog />
		</>
	)
}
