import type { AxiosRequestTransformer } from 'axios'

export const formDataTransformer: AxiosRequestTransformer = (data) => {
	const body = new FormData()

	Object.keys(data).forEach((key: string) => {
		body.append(key, data[key])
	})

	return body
}
