import {
	Archive,
	Armchair,
	Ban,
	Building,
	Calendar,
	CalendarClock,
	CalendarSearch,
	Check,
	Copy,
	Croissant,
	EllipsisVertical,
	Eye,
	FilePen,
	FileSpreadsheet,
	GripVertical,
	Group,
	Link,
	LogOut,
	Package,
	PackageCheck,
	PackageX,
	Pen,
	Plus,
	Printer,
	RotateCw,
	Save,
	Scale,
	Settings,
	TicketPlus,
	Trash2,
	Truck,
	Undo2,
	UserRound,
	UserRoundCheck,
	UsersRound,
	X,
} from 'lucide-react'

export const iconsRef = {
	describes: {
		accounts: UsersRound,
		cohorts: Group,
		events: Calendar,
		extras: Croissant,
		invoices: FileSpreadsheet,
		params: Settings,
		orders: Package,
		overstays: CalendarClock,
		planning: CalendarSearch,
		providers: Truck,
		quotations: FilePen,
		rules: Scale,
		denylist: Ban,
		spaces: Armchair,
		users: UserRound,
		venues: Building,
	},
	actions: {
		archive: Archive,
		cancel_event: Ban,
		cancel: Ban,
		close: X,
		create: Plus,
		createEntry: Plus,
		createLink: Link,
		delete: Trash2,
		drag: GripVertical,
		duplicate: Copy,
		edit: Pen,
		logout: LogOut,
		menu: EllipsisVertical,
		print: Printer,
		reassign: UserRoundCheck,
		rebuild: RotateCw,
		reject_order: PackageX,
		save: Save,
		sorry_code: TicketPlus,
		undo: Undo2,
		validate_order: PackageCheck,
		validate: Check,
		view: Eye,
		void: Trash2,
	},
} as const
